import { directus } from "wrapper"

const put_data = (key, data) => ({
  type: "PUT_DATA",
  key,
  data,
})

export const get_article = (slug) => {
  return (dispatch) => {
    console.log(slug)
    directus
      .get(
        `items/articles?limit=5&fields=*.*.*&filter[status]=published&filter[is_top_issue]=1&filter[_and][0][_and][0][game][slug][_eq]=${slug}&sort=-like_count`
      )
      .then((resp) => {
        dispatch(put_data("top_article", resp))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const get_category_article = (slug, type) => {
  return (dispatch) => {
    dispatch(put_data(type, false))

    directus
      .get(
        `items/articles?limit=5&fields=*.*.*&filter[status]=published&filter[_and][0][_and][0][game][slug][_eq]=${slug}&filter[_and][0][_and][0][type][slug][_eq]=${type}&sort=-like_count`
      )
      .then((resp) => {
        dispatch(put_data(type, resp))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
