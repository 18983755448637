import React, { useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import ErrorBoundary from "ErrorBoundary";
import io from "socket.io-client";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { messaging, askForPermissioToReceiveNotifications } from 'firebase/push-notification';

import Navbar from "partial/Navbar";
import Banner from "partial/Banner";
import Footer from "partial/Footer";
import AlertModal from "partial/AlertModal";
import Loader from "partial/Loader";
import Chat from "partial/Chat";

import Home from "pages/Home";
import FAQ from "pages/FAQ";
import Post from "pages/Post";
import Ticket from "pages/Ticket";
import List from "pages/List";
import Search from "pages/Search";

import NoMatch from "./404";
import Login from "./Login";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/index.scss";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import NotificationModal from "partial/NotificationModal";
import { getUrlParam } from "helper";

window.socket = io.connect(process.env.REACT_APP_SOCKET_URL, { transports: ["websocket"] });

const Main = (props) => {
	const { banner_img, games, user, alert_open, alert_title, alert_content, notification_open, alert_context } =
		props.main;
	const { main, actions } = props;

	const handleRedirect = () => {
		const url = window.localStorage.getItem("last_page");
		if (url) {
			window.location.href = url;
			window.localStorage.removeItem("last_page");
		}
	};

	const checkUserAgent = () => {
		let userAgent = navigator.userAgent.match(/Chrome\/([1]{1}[7-9]{1}|[2-9]{1}[0-9]{1})/);

		if (userAgent) {
			if (userAgent[1] < 50) {
				return false;
			}
		}

		return true;
	};

	useEffect(() => {
		const errorParam = getUrlParam("err");
		if (errorParam !== "maintenance") {
			handleRedirect();
		}
	});

	useEffect(() => {
		actions.get_translate();
		actions.set_token();
		actions.get_game();
		// actions.get_category()
		actions.check_login();

		const errorParam = getUrlParam("err");
		if (errorParam === "maintenance") {
			actions.toggleAlert();
			actions.put_alert_content("Maintenance", "Website Sedang Dalam Maintenance");
			window.localStorage.removeItem("last_page");
		}
	}, [actions]);

	useEffect(() => {
		if (process.env.NODE_ENV === "production" && checkUserAgent()) {
			import("firebase/push-notification").then((myModule) => {
				// myModule.askForPermissioToReceiveNotifications()
				myModule.messaging.onMessage((payload) => {
					console.log("Foreground Triggered");

					let position = window.screen.width < 700 ? "top-center" : "bottom-right";

					toast(
						<>
							<p>{payload.data.message_title}</p>
							<p>{payload.data.message_body}</p>
						</>,
						{
							className: "toastCont",
							progressClassName: "toastProgress",
							position: position,
							autoClose: 3000,
							hideProgressBar: false,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: true,
							onClick: () =>
								(window.location.href =
									process.env.REACT_APP_BASE_URL + `ticket/list/${payload.data.ticket_number}`)
						}
					);
				});
			});
		} else {
			console.log("Not Compatible");
		}

		if (!("Notification" in window)) {
			console.log("This browser does not support desktop notification");
		} else {
			if (Notification.permission === "default" && main.user) {
				console.log("trigger push notif!");
				actions.toggleNotification(true);
			} else if (Notification.permission === "granted" && main.user && !window.localStorage.getItem("register")) {
				import("firebase/push-notification").then((myModule) => {
					myModule.askForPermissioToReceiveNotifications();
				});
			}
		}
	}, [main.user, actions]);

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<ErrorBoundary>
				<div id="Main" className="main-panel">
					<>
						<Navbar data={user} lang={main.active_lang} {...main} actions={actions} />
						<Banner data={games} image={banner_img} />
						<div className="content-container">
							<Switch>
								<Route exact path="/" component={Home} />
								<Route
									exact
									path="/ticket/:type/:num"
									component={user ? Ticket : user === undefined ? Loader : Login}
								/>
								<Route
									exact
									path="/ticket/:type"
									component={user ? Ticket : user === undefined ? Loader : Login}
								/>
								<Route exact path="/:games/faq/" component={FAQ} />
								<Route exact path="/:games/post/:slug" component={Post} />
								<Route exact path="/:games/list/:type" component={List} />
								<Route exact path="/search/:query" component={Search} />
								<Route
									exact
									path="/ftm"
									render={() => {
										window.location.href = "https://help.ftm.garena.co.id/";
									}}
								/>
								<Route component={NoMatch} />
							</Switch>
						</div>
						<AlertModal
							open={alert_open}
							closeModal={actions.toggleAlert}
							title={alert_title}
							content={alert_content}
							context={alert_context}
						/>
						<NotificationModal
							open={notification_open}
							closeModal={() => actions.toggleNotification(false)}
						/>
						<ToastContainer />
						<Footer />
						{window.location.pathname !== "/" && <Chat data={user} />}
					</>
				</div>
			</ErrorBoundary>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	main: state.main
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
