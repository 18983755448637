import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "partial/Loader";

// STYLING
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./scss/Accordion.module.scss";

// COMPONENT
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "pages/FAQ/redux/actions";

const ls = window.localStorage;

class AccordionComp extends Component {
	constructor(props) {
		super(props);
		this.header = React.createRef();
	}

	get_localize = (data) => {
		return data.translations.find?.((e) => e.languages_code.code === ls.lang);
	};

	handleClick = (e, slug, type) => {
		const { actions } = this.props;

		let all_arrow = document.querySelectorAll(".headerCont__arrow");
		let current_arrow = e.currentTarget.querySelector(".headerCont__arrow");

		if (!current_arrow.classList.contains("active")) {
			actions.get_category_article(slug, type);
		}

		all_arrow.forEach((element) => {
			if (element !== current_arrow) {
				element.classList.remove("active");
			} else {
				element.classList.toggle("active");
			}
		});
	};

	populateLink = (type) => {
		const { faq, slug, translation } = this.props;

		if (!faq[type]) {
			return <Loader />;
		}

		if (faq[type].length > 0 && faq[type][0].type) {
			let list;
			list = faq[type].map((dt, idx) => (
				<Link className={styles.link} key={idx} to={`/${dt.game.slug}/post/${dt.slug}`}>
					<span>{this.get_localize(dt)?.title}</span>
					<span>&gt;</span>
				</Link>
			));

			list.push(
				<Link className={styles.link} key={"other"} to={`/${slug}/list/${type}`}>
					<span>{translation.txt_see_more}</span>
					<span>&gt;</span>
				</Link>
			);
			return list;
		}

		return (
			<span className={styles.link}>
				<span>{translation.txt_no_data}</span>
			</span>
		);
	};

	populateAccordion = () => {
		const { category, slug, translation } = this.props;

		if (category.length > 0) {
			return category.map((dt, idx) => {
				return (
					<Card className={styles.card} key={idx}>
						<>
							<Accordion.Toggle
								ref={this.header}
								onClick={(e) => this.handleClick(e, slug, dt.slug)}
								as={Card.Header}
								variant="link"
								eventKey={`${idx}`}
							>
								<div className={styles.headerCont}>
									<span>
										{translation.txt_about?.toUpperCase()}{" "}
										{this.get_localize(dt)?.name.toUpperCase()}
									</span>
									<span className={`headerCont__arrow`}>&#9660;</span>
								</div>
							</Accordion.Toggle>

							<Accordion.Collapse eventKey={`${idx}`}>
								<Card.Body>{this.populateLink(dt.slug)}</Card.Body>
							</Accordion.Collapse>
						</>
					</Card>
				);
			});
		}
		return "";
	};

	render() {
		return <Accordion>{this.populateAccordion()}</Accordion>;
	}
}

function mapStateToProps(state) {
	return { faq: state.faq, translation: state.main.translation };
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AccordionComp);
