import React, { Component } from "react";
import styles from "./scss/Faq.module.scss";

import Title from "partial/Title";
import AccordionComp from "partial/Accordion";
// import FAQLink from "partial/Link"
import Carousel from "partial/Carousel";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./redux/actions";
import * as actionsBanner from "base/redux/actions";

class FAQ extends Component {
	componentDidMount() {
		const { actions, match, bannerActions } = this.props;
		actions.get_article(match.params.games);
		bannerActions.get_category(match.params.games);
		bannerActions.put_banner("change");
	}

	render() {
		const { match, translation } = this.props;
		const { category } = this.props.main;
		const { all_article, top_article } = this.props.faq;

		return (
			<div id="FAQ" className={`container pt-5 pb-5 ${styles.faqCont}`}>
				<Title>{translation.txt_hot_issue}</Title>
				<div className={`row justify-content-md-center ${styles.faqCont__list}`}>
					<div className="col">
						<Carousel data={top_article} other={`/${match.params.games}/list/all`} />
						{/* <FAQLink data={top_article} other={`/${match.params.games}/list/all`} /> */}
					</div>
				</div>
				<div className="row justify-content-md-center pt-5">
					<div className="col">
						<AccordionComp category={category} slug={match.params.games} data={all_article} />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		faq: state.faq,
		main: state.main,
		translation: state.main.translation
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		bannerActions: bindActionCreators(actionsBanner, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
