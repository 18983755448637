import { directus } from "wrapper"

export const get_related_article = (slug, query) => {
	return (dispatch) => {
		dispatch({
			type: "PUT_RELATED_ARTICLE",
			data: undefined,
		})

		let url = `${process.env.REACT_APP_DIRECTUS}items/articles?fields=*.*&filter[status][_eq]=published`
		url += slug === "all" ? "" : `&filter[_and][0][_and][0][game][slug][_eq]=${slug}`
		url +=
			query === "all" || query === "top issue" ? "" : `&filter[_and][0][_and][0][type][slug][_eq]=${query}`

		directus
			.get(url)
			.then((resp) => {
				dispatch({
					type: "PUT_RELATED_ARTICLE",
					data: resp,
				})
			})
			.catch((err) => {
				console.log(err)
			})
	}
}
